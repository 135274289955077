import styles from "../styles.module.scss";
import { ReactComponent as MailIcon } from "../../../assets/svg/mail.svg";
import { Card } from "./Card";

interface IProps {
  count?: number;
  isDragging: boolean;
}

export default function MailCard({ count = 0, isDragging }: IProps) {
  return (
    <Card  backgroundColor="#1973bb" isDragging={isDragging}>
      <a target="blanc" href="#" ><div className={`${styles.iconWrapper} ${styles.mail}`}>
        <MailIcon height={40} width={40} />
        {count > 0 && <h4 className={styles.messageCount}>0</h4>}
      </div>
      <div>
        <h4 className={styles.msgCardTitle}>Messages</h4>
      </div></a>
    </Card>
  );
}
